* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --html-font-size: 62.5%;
  --body-font-size: 12px;
  --body-font-size-heading: 2rem;
  --body-font-size-xsmall: 1rem;
  --body-font-size-small: 1.2rem;
  --body-font-size-mid: 1.3rem;
  --body-font-size-large: 1.4rem;
  --body-font-size-xlarge: 1.6rem;
}

html {
  font-size: var(--html-font-size);
}

body {
  height: 100vh;
  font-family: "Lato", Sans-serif;
  background-color: #dee4f4;
  font-size: var(--body-font-size);
}

@media only screen and (min-height: 62.5em) and (max-height: 67em) {
  :root {
    --html-font-size: 79%;
    --body-font-size: 1.265rem;
  }
}

@media only screen and (min-height: 67.1em) {
  :root {
    --html-font-size: 82.5%;
    --body-font-size: 1.212rem;
  }
}

/* max-height: 690px, min-width: 900px */
@media only screen and (max-height: 43.125em) and (min-width: 56.25em) {
  :root {
    --html-font-size: 57%;
    --body-font-size: 1.754rem;
  }
}

#root {
  height: 100%;
}

.custom-textfield {
  width: 100%;
  margin: 0.5em 0 !important;
}

.custom-textarea {
  width: 100%;
  margin: 0.5em 0 0 0 !important;
  resize: none;
  padding: 1em;
  outline: 0;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
}

.custom-textarea:focus {
  outline: 2px solid #5298dd !important;
  border: 1px solid transparent;
}

.custom-textarea:hover {
  outline: 1px solid #000000;
  border: 1px solid transparent;
}

.react-swipeable-view-container {
  /* height: 81vh; */
}

iframe[name="editorFrame"] {
  /* height: 100%;
  width: calc(100% - 290px); */
}

.MuiAutocomplete-popper {
  z-index: 999999 !important;
}

.snackbar {
  z-index: 999999 !important;
}

.dot-green {
  height: 15px;
  width: 15px;
  background-color: #09de1f;
  border-radius: 50%;
  display: inline-block;
}

.dot-yellow {
  height: 15px;
  width: 15px;
  background-color: #ffd233;
  border-radius: 50%;
  display: inline-block;
}

.dot-grey {
  height: 15px;
  width: 15px;
  background-color: #d9d9d9;
  border-radius: 50%;
  display: inline-block;
}

.icon-button-custom {
  border: 1px solid #dadada;
  /* M3/Elevation Light/1 */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 14px !important;
  margin: 10px !important;
}

.triangle-tip {
  content: "";
  height: 1rem;
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid #D5B274;
  background: #D5B274;
  color: #fff;
}

.gm-style-moc {
  z-index: -1 !important;
  opacity: 0 !important;
}
p{
  font-family: "Lato" !important;
}


.css-1q60rmi-MuiAutocomplete-endAdornment{
  top:auto !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    padding-top: 15px;
    padding-bottom: 15px;
}