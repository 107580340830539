.issuerPopup{
    background-color: #D5B274; 
    width: 89%;
    z-index: 50; 
    position: absolute;
    bottom: 76%;
    border-radius: .8rem .8rem 0 0;
    transition: height .2s;
    height: 0;
    padding: 1.25rem;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    row-gap: 1rem;
    padding-bottom: 0;
}

@media only screen and (max-width: 1440px) {
  .issuerPopup{
    bottom: 55%
  }
}

@media only screen and (min-width: 1441px) {
  .issuerPopup{
    bottom: 62%
  }
}

@media only screen and (min-width: 1440px) and (-webkit-min-device-pixel-ratio: 2) {
  /* retina */
  .issuerPopup{
    bottom: 59%
  }
}


.issuerPopup-active{
  height: 13rem;
  padding-left: 1.25rem;
}